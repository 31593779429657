/* Blog.css */
.blog-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f0f0f0; /* Add background color */
  }
  
  .blog-title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
  }
  
  .post {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 20px;
  }
  
  .post .username {
    font-weight: bold;
    font-size: 18px;
    color: #333;
    margin-bottom: 5px;
  }
  
  .post .timestamp {
    font-size: 14px;
    color: #777;
  }
  
  .post .message {
    font-size: 16px;
    color: #555;
    line-height: 1.5;
  }
  