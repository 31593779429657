/* Home.css */
.home {
    background-color: #f0f0f0;
    padding: 20px;
    text-align: center;
    min-height: calc(100vh - 70px); /* Adjust for the height of the navbar */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
  }
  
  .home h1 {
    font-size: 36px;
    margin-bottom: 10px;
    font-weight: bold;
    color: #333; /* Match the color of your navbar text */
  }
  
  .home img {
    max-width: 80%; /* Adjust to your desired width */
    max-height: 400px; /* Adjust to your desired height */
    height: auto;
    margin: 0px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .home h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .home p {
    font-size: 18px;
    color: #555;
    line-height: 1.5;
  }

  
  
  /* Add more styles as needed */
  