/* Post.css */
.post {
    border: 1px solid #ddd;
    padding: 10px;
    margin: 10px 0;
    background-color: #fff;
  }
  
  .post-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  
  .post-username {
    font-weight: bold;
  }
  
  .post-timestamp {
    color: #888;
    font-size: 0.9rem;
  }
  
  .post-message {
    font-size: 1rem;
  }
  