/* Navbar.css */
nav {
  background-color: #333;
  color: #fff;
}

nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
}

nav li {
  margin: 0 10px;
}

nav a {
  text-decoration: none;
  color: #fff;
}

nav a:hover {
  text-decoration: underline;
}

/* Add the styles for the Footer */
footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  position: fixed; /* Set the position to fixed */
  bottom: 0; /* Stick it to the bottom */
  width: 100%; /* Make it span the full width */
}

footer p {
  margin: 0;
}
